<template>
  <div class="titleCodes">
    <!--新增/编辑账号-->
    <el-dialog :title="language.GoogleAuthenticator" center :visible.sync="dialogFormVisibleAdd" width="280px"
      :show-close="true" :before-close="closeDialog" :close-on-click-modal="true" :close-on-press-escape="false">
      <div class="titles">{{ language.ScanQRcodetobind }}</div>
      <div class="users-add">
        <img class="qrcode" :src="modifys" alt="">
      </div>
      <div class="titles" style="color:red;word-break: break-word;">{{ language.ScanCodePrompt }}</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    language: {
      type: Object,
      default: () => { },
    },
    dialogFormVisibleAdd: {
      type: Boolean,
      default: false,
    },
    modifys: {
      type: String,
      default: 'https://api.qrserver.com/v1/create-qr-code/?data=otpauth%3A%2F%2Ftotp%2FHboPay%3Fsecret%3DR7NF6LBUADUTJYQW&size=200x200&ecc=M',
    },
  },
  watch: {
    dialogFormVisibleAdd() {
      if (this.dialogFormVisibleAdd) {
        // this.handleCheck();
      }
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },

  mounted() {

  },
  methods: {
    //关闭dialog
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
<style lang="scss">
.titleCodes {
  .el-dialog--center .el-dialog__body {
    text-align: initial;
    padding: 0px 20px 15px 20px !important;
  }

  .titles {
    text-align: center;
    margin-bottom: 0px;
  }

  .users-add {
    display: block;
    overflow: hidden;
    width: 200px;
    min-height: 203px;
    margin: auto;

    .qrcode {
      transform: scale(0.8);
    }
  }
}
</style>